.Feed {
    padding: 20px;
    background-color: #f0f0f0;
  }
  
  .Feed input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .Feed button {
    padding: 10px;
  }
  
  .posts {
    margin-top: 20px;
  }
  
  .Post {
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .comments {
    margin-top: 10px;
  }
  
  .comment {
    background-color: #f9f9f9;
    padding: 5px;
    border-radius: 3px;
    margin-top: 5px;
  }
  
  