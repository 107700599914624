/* lessons.css */
.lessons-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px; /* Adjust as needed */
  }
  .projects-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px; /* Adjust as needed */
  }
  .activities-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px; /* Adjust as needed */
  }
  
  .subjects-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  
  .subject-box {
    width: 200px; /* Adjust width as needed */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f0f0f0;
  }
  
  .subject-box h2 {
    margin-bottom: 10px;
  }
  
  .lesson {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
  }
  
  .lesson:hover {
    background-color: #e0e0e0;
  }
  
  .viewing-area {
    flex: 1;
    margin: 0 20px; /* Adjust margins as needed */
  }
  
  .selected-lesson {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
  }
  
  .selected-lesson h2 {
    margin-bottom: 10px;
  }
  
  .selected-lesson p {
    margin-bottom: 10px;
  }
  
  button {
    margin-top: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  