body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  background-color: #000000;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.navbar-logo {
  color: #ffffff;
  font-size: 3em;
  font-weight: bold;
  text-decoration: none;
}

.navbar-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-item {
  margin-left: 20px;
}

.navbar-link {
  color: #ffffff;
  font-size: 1.5em;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
}

.navbar-link:hover {
  background-color: #575757;
}

.navbar-logo-image {
  width: 55px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Adjust the spacing between the logo image and text */
}