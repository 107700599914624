/* src/components/profileNavbar.css */
.profile-navbar {
  display: flex;
  justify-content: space-around;
  background-color: #333;
  padding: 10px;
}

.profile-navbar a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
}

.profile-navbar a.active {
  background-color: #555;
}
