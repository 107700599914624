.profile-page {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 20px;
    padding: 20px;
    background-color: rgb(144, 183, 243);
  }
  
  .sidebar {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .box {
    padding: 125px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: rgb(202, 113, 131);
  }
  
  .main-content {
    text-align: center;
  }
  
  .feed {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #ccc;
  }
  