body {
    font-family: Arial, sans-serif;
  }
  
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .login-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-header h2 {
    font-size: 2em;
    color: #333;
  }
  
  .login-header p {
    font-size: 1em;
    color: #666;
  }
  
  .login-box {
    background-color: #ffffff;
    padding: 30px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .login-box h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  