/* src/components/NewPost.css */

.new-post {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 600px;
    margin: 0 auto 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .new-post h2 {
    color: #333333;
    margin-bottom: 20px;
  }
  
  .new-post form {
    display: flex;
    flex-direction: column;
  }
  
  .new-post input[type="text"],
  .new-post textarea {
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 16px;
  }
  
  .new-post input[type="text"]:focus,
  .new-post textarea:focus {
    border-color: #888888;
    outline: none;
  }
  
  .new-post textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .new-post button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .new-post button:hover {
    background-color: #45a049;
  }
  
  .new-post div[style="color: red"] {
    color: red;
    margin-bottom: 15px;
  }
  