.home-container {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
    text-align: center;
  }
  
  .header {
    background-color: #68aff6;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .header h1 {
    margin: 0;
    font-size: 2.5em;
    color: #333;
  }
  
  .header p {
    font-size: 1.2em;
    color: #666;
  }
  
  .welcome-box {
    background-color: #ffffff;
    padding: 30px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .welcome-box h2 {
    font-size: 2em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .welcome-box p {
    font-size: 1em;
    margin-bottom: 20px;
    color: #666;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .image-placeholder {
    flex: 1;
    background-color: #f1f1f1;
    height: 200px;
    border: 1px solid #ddd;
    border-radius: 10px;
  }
  
  .post-section {
    flex: 2;
  }
  
  footer {
    margin-top: 20px;
  }
  
  footer p {
    color: #666;
  }
  